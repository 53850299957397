import React, { useState } from 'react';
import * as language from '../constants/languages';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';

export default function HomeBannerCarousal({ data }) {
    const navigate = useNavigate()
    // banner image pressed
    const homeBannerImage = (item) => {
        if (item.offerDetails == null) {
            navigate('/Terms&Conditions', { heading: 'Offer Terms & Conditions', state: { offerDetails: item.longDescription, pageId: 3 } })
        } else if (item.offerDetails.length == 1) {
            navigate(`/Productdetails/${item.offerDetails[0].productId}`)

        } else if (item.offerDetails.length > 1) {
            var offerItems = [];
            for (let i of item.offerDetails) {
                offerItems = [...offerItems, i.product]
            }

            navigate('/Products', {
                state: {

                    heading: item.offername, offerId: item.offerId, isOffer: 1, params: {
                        identity: 'offer',
                        offerId: item.offerId,
						isOffer: 1
                    },
                }
            })

        }

    }
    return (
        <Carousel variant="dark" indicators={true} controls={true}>
            {
                data.map((item, index) => (
                    <Carousel.Item key={index} onClick={() => homeBannerImage(item)}>
                        <img src={item.image} width=" 100%" height="auto" style={{ borderRadius: 10 }} />
                    </Carousel.Item>

                ))

            }
        </Carousel>
    )
}